@import url("https://fonts.googleapis.com/css2?family=Outfit:wght@100..900&display=swap");

/* Títulos e imágenes */
.inicio-title {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.inicio-title h2 {
  position: relative;
  display: inline-flex;
  align-items: center;
  gap: 15px;
  font-size: 28px;
  font-family: "Outfit", sans-serif;
  font-weight: 500;
  padding: 30px 0 15px;
  color: #000;
}

.inicio-title h2::before,
.inicio-title h2::after {
  content: "";
  display: block;
  width: 50px;
  height: 3px;
  background: #000;
}

.inicio-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Productos */
.productos-section {
  text-align: center;
}

.inicio-title {
  margin-bottom: 10px;
  color: #303031;
}

.inicio-title h3 {
  display: inline-flex;
  max-width: 950px;
  font-family: "Outfit", serif;
  font-size: 16px;
  font-weight: 400;
  padding: 0 20px 30px 20px;
}

/* Login (si aplica) */
.login-icon {
  width: 1.8em;
  height: 1.8em;
  margin-left: 0.5em;
  cursor: pointer;
}

#email-label,
#password-label {
  color: #000 !important;
}

/* Ajustes para móviles */
@media (max-width: 600px) {
  .imagen-contenedor img {
    height: 400px;
  }
  .imagen-contenedor h2 {
    position: absolute;
    transform: translate(0, 0);
    color: #fff;
    font-size: 30px;
    padding: 10px;
    font-weight: 600;
    width: 100%;
    text-align: center;
    font-family: "Outfit", sans-serif;
  }
  .inicio-title h2 {
    font-size: 28px;
    color: #303031;
  }
  .inicio-title h3 {
    padding: 0 20px 20px;
    font-size: 16px;
  }
  .foto-beneficios img {
    width: 0;
    height: auto;
  }
}

/* Blog */
.blog-title {
  margin-top: 80px;
  color: #303031;
}

.blog-link {
  text-decoration: none;
  color: inherit;
  font-size: 3rem;
  font-weight: bold;
  font-family: "Outfit", sans-serif;
}

/* Imagen de inicio */
.inicio-imagen {
  width: 100%;
  height: 480px;
  object-fit: cover;
}

.imagen-contenedor {
  position: relative;
}

.texto-encima {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  color: white;
  font-size: 35px;
  padding-left: 10px;
  font-weight: 600;
  width: 35%;
  text-align: left;
  font-family: "Outfit", sans-serif;
}

/* Carrusel */
.textoCarruselInicio {
  font-family: "Outfit", sans-serif;
}

/* Categorías */
.section-categorias {
  padding: 20px 20px 70px 20px;
  max-width: 980px;
  margin: 0 auto;
}

.card-text-overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0,0,0,0.5);
  color: white;
  padding: 10px;
  text-align: center;
}

.boton-categoria button {
  text-decoration: none;
  font-family: Outfit, sans-serif;
  font-weight: 500;
  position: absolute;
  bottom: 20px;
  background-color: #b63232;
  padding: 4px 14px;
  color: #fff;
  border-radius: 5px;
  border: 2px solid #b63232;
}

.boton-categoria button:hover {
  background-color: #fff;
  border: 2px solid #b63232;
  color: #b63232;
  font-weight: bold;
}

.boton-categoria-mas {
  margin-top: 0;
}

.boton-categoria-mas button {
  background-color: #fff;
  border: 2px solid #fff;
  padding: 4px 14px;
  font-family: "Outfit", sans-serif;
  font-weight: bolder;
  color: #b63232;
  border-radius: 8px;
}

.boton-categoria-mas button:hover {
  background-color: #fff;
  border: 2px solid #fff;
  color: #b63232;
  font-weight: bolder;
}

.descripcionCategoria {
  font-family: "Outfit", sans-serif !important;
}

/* Beneficios */
.section-beneficios {
  padding-bottom: 60px;
}

.Beneficios grid {
  display: flex;
  flex-direction: end;
}

.Beneficios {
  padding: 30px 0 0 220px;
}

.zona-texto-beneficios {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  padding-bottom: 35px;
}

.texto-beneficios {
  text-align: start;
}

.texto-beneficios h4 {
  font-family: "Outfit", sans-serif;
  font-size: 22px;
  font-weight: bold;
  color: #000;
}

.texto-beneficios p {
  font-family: "Outfit", serif;
  color: #303031;
}

.icono-beneficios {
  background-color: #b63232;
  padding: 15px 18px;
  border-radius: 50%;
  color: #fff;
}

.foto-beneficios {
  padding-left: 20px;
}

.foto-beneficios img {
  width: 450px;
  height: 400px;
  object-fit: cover;
  display: flex;
}

/* Modo oscuro */
.dark-mode {
  background-color: #121212 !important;
  color: #fff !important;
}

.dark-mode h3,
.dark-mode h4,
.dark-mode p {
  color: #fff !important;
}

.dark-mode .inicio-title h3 {
  color: #fff !important;
  background-color: #121212 !important;
}

.dark-mode .texto-encima {
  background-color: transparent !important;
  color: #fff !important;
  filter: brightness(200%);
  font-weight: normal !important;
}

.dark-mode .inicio-title h2 {
  color: whitesmoke !important;
  background-color: #121212 !important;
  border: 0 solid #fdfdfd;
  border-radius: 5em;
  padding: 0.2em 0.4em;
  margin-top: 1.2em !important;
  margin-bottom: 0.5em !important;
  max-width: 500px !important;
}

.dark-mode .inicio-title h2::before,
.dark-mode .inicio-title h2::after {
  background-color: #121212 !important;
}

.dark-mode .porQueElegirnos {
  color: whitesmoke !important;
  border-radius: 1.5em;
  background-color: #333 !important;
  padding: 0.5em 0.8em;
  margin-top: 1.2em !important;
  margin-bottom: 0.3em !important;
  display: inline-block;
}

.dark-mode .productos-section {
  color: #fff !important;
  background-color: #121212 !important;
}

.dark-mode .css-9v99vz-MuiPaper-root-MuiCard-root {
  background-color: #121212 !important;
}

@media (max-width: 768px) {
  .logo-button {
    width: 60px;
    height: 45px;
  }
}