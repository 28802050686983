/* Estilos del modo oscuro */

/* Estilos de la hamburguesa */

.css-h4y409-MuiList-root {
  font-family: "Outfit", sans-serif !important;
}

.logo-button {
  max-width: 40% !important;
}

/* Estilos del icono del navbar */

.dark-mode .logo-button {
  filter: invert(0%) !important;
}

/* Estilos del icono de busqueda */

.dark-mode .css-1o0khwb-MuiSvgIcon-root {
  color: white !important;
}

.dark-mode .hcJuhC .carrito-img {
  filter: invert(0%) !important;
}

/* Hover del navbar */

.dark-mode .hcJuhC .menu-item:hover {
  border-bottom: 3px solid rgb(182, 50, 50) !important;
  padding-bottom: 4px;
}

/* Navbar lateral derecho */

.dark-mode .css-1hskriy {
  background-color: black !important;
  color: white !important;
}

.dark-mode .css-1160xiw-MuiPaper-root-MuiDrawer-paper {
  background-color: black !important;
  color: white !important;
}

/* Ícono de la hamburguesa */

.dark-mode .hamburger > svg:nth-child(1) {
  filter: invert(100%) !important;
}

/* Hover del navbar izquierdo */

.MuiList-root > li:hover {
  background-color: rgb(182, 50, 50)  !important;
}

/* Navbar izquierdo */

.css-4t3x6l-MuiPaper-root-MuiDrawer-paper {
  background-color: white !important;
}

.dark-mode .css-4t3x6l-MuiPaper-root-MuiDrawer-paper {
  background-color: black !important;
}

.hamburgerNavbar {
  background-color: white !important;
}

.isotipoHamburgerOnLoop {
  filter: invert(100%) !important;
}

.dark-mode .isotipoHamburgerOnLoop {
  filter: invert(0%) !important;
}

/* Isotipo del navbar izquierdo */

.contenedorContactanosHamburger {
  align-items: center;
  display: flex;
  flex-direction: column;
  padding: 1rem;
  text-align: center;
}

.menu {
  margin-left: "12em";
}