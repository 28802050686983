* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Outfit", sans-serif !important;
}

.producto-detalle img {
  width: 100%;
}

.producto-detalle-info h3 {
  font-size: 2rem;
}

.categoria {
  font-size: 0.8rem;
  color: #424242;
  margin-top: 1rem;
}

.precio {
  font-size: 2rem;
  margin-top: 2rem;
  font-weight: 600;
}

.item-count {
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-top: 1rem;
}

.item-count button,
.agregar-al-carrito,
.enviar {
  background-color: var(--clr-main);
  border: 0;
  width: 2rem;
  height: 2rem;
  color: white;
  border-radius: 0.5rem;
  cursor: pointer;
  font-size: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  line-height: 1;
  border: 2px solid var(--clr-main);
  transition: 0.2s;
}

.item-count button:hover,
.agregar-al-carrito:hover,
.enviar:hover {
  background-color: white;
  color: black;
}

.agregar-al-carrito,
.enviar {
  width: auto;
  height: auto;
  margin-top: 1rem;
  padding: 1rem;
}

.formulario {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  align-items: flex-start;
}

.formulario input {
  padding: 0.5rem;
  width: 18rem;
  border-radius: 0.5rem;
  border: 2px solid black;
}

.formulario input:focus {
  outline: 0;
  border: 2px solid var(--clr-main);
  outline: 2px solid var(--clr-main);
}

.carousel {
  display: flex;
  overflow-x: auto; /* Enable horizontal scrolling */
  width: 100%; /* Adjust width as needed */
  margin: 0 auto; /* Center the carousel */
}

.carousel-item {
  flex: 0 0 33%; /* Set item width (adjust as needed) */
  margin: 0 10px; /* Add spacing between items */
  text-align: center;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.carousel-item img {
  width: 100%; /* Make images responsive */
  height: 200px; /* Adjust image height as needed */
  object-fit: cover; /* Maintain aspect ratio and cover the space */
  margin-bottom: 10px;
}

.carousel-item h2 {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 5px;
}

.carousel-item p {
  color: #666;
  line-height: 1.6;
}

.carousel-item .price {
  font-weight: bold;
  color: #333;
}

@import url("https://fonts.googleapis.com/css2?family=Manjari:wght@400;700&family=Outfit:wght@700&display=swap");

/* Estilos generales */
.typography-manjari {
  font-family: "Outfit", sans-serif;
}

.typography-outfit-bold {
  font-family: "Outfit", sans-serif;
  font-weight: 700;
}

/* Otros estilos específicos según sea necesario */

/* Estilos del modo oscuro */
.dark-mode {
  background-color: #121212;
  color: #ffffff !important;
}

/* Puedes personalizar otros elementos */
.dark-mode .navbar {
  background-color: #1e1e1e !important;
}

.dark-mode .slider {
  background-color: #444;
}

.dark-mode .slider:before {
  background-color: white;
  box-shadow: inset 8px -4px 0px 0px #f0f0f0;
}

/* .dark-mode h2, */
.dark-mode h3,
.dark-mode h4,
.dark-mode p,
.dark-mode .inicio-title h2,
.dark-mode .inicio-title h3,
.dark-mode .texto-beneficios h4,
.dark-mode .texto-beneficios p,
.dark-mode .blog-title,
.dark-mode .categoria,
.dark-mode .precio {
  color: #ffffff !important;
}

.dark-mode .productos-section {
  background-color: #121212 !important;
}

.dark-mode .inicio-title h2::before,
.dark-mode .inicio-title h2::after {
  background: #ffffff !important;
}

body.dark-mode .nav {
  background-color: hsl(0, 0%, 12%) !important;
}

body.dark-mode .menu-item {
  border: transparent !important;
  color: white;
}

.dark-mode .inicio-title .h2 {
  background-color: #121212;
  color: white !important;
}

.dark-mode .categoryButton {
  filter: invert(100%) !important;
}

.dark .custom-pagination .MuiPaginationItem-root {
  background-color: #333 !important;
  color: white !important;
  border-color: #333 !important;
}

.dark .custom-pagination .Mui-selected {
  background-color: #333 !important;
  color: white !important;
  font-weight: bold;
}

.dark .custom-pagination .MuiPaginationItem-root:hover {
  background-color: #333 !important;
}

.dark-mode .porQueElegirnos {
  background-color: #333333 !important;
  border: 0px solid rgb(253, 253, 253);
  border-radius: 0em;
}

.dark-mode .beneficiosBox {
  background-color: #121212;
  border: 0px solid rgb(253, 253, 253);
  border-radius: 0.5em;
}

/* Copia de Inicio.css */

@import url("https://fonts.googleapis.com/css2?family=Outfit:wght@100..900&display=swap");

/* INCIO DE ESTILOS TITULOS */
.inicio-title {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.inicio-title h2 {
  position: relative;
  display: inline-flex;
  align-items: center;
  gap: 15px;
  font-size: 28px;
  font-family: "Outfit", sans-serif;
  font-weight: 500;
  padding-bottom: 15px;
  padding-top: 30px;
  color: #000000;
}

.inicio-title h2::before,
.inicio-title h2::after {
  content: "";
  display: block;
  width: 50px;
  height: 3px;
  background: #000000;
}

/* Estilos generales del contenedor */
.inicio-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Estilos para la sección de productos */
.productos-section {
  text-align: center;
}

/* Estilos para el título de productos */
.inicio-title {
  margin-bottom: 10px;
  color: #303031;
}

.inicio-title h3 {
  display: inline-flex;
  max-width: 950px;
  font-family: "Outfit", sans-serif;
  font-size: 16px;
  font-weight: 400;
  padding: 0px 20px 30px 20px;
}

@media (max-width: 600px) {
  .imagen-contenedor img {
    height: 400px;
  }

  .imagen-contenedor h2 {
    position: absolute; /* Puedes ajustar esto para mover el texto hacia arriba o abajo */
    /* Alinea el texto a la izquierda */
    transform: translateY(0%);
    transform: translateX(0%);
    /* Centra el texto verticalmente */
    color: rgb(255, 255, 255);
    /* Cambia el color del texto según tus necesidades */
    font-size: 30px;
    /* Ajusta el tamaño del texto */
    padding-left: 0px;
    padding: 10px;
    /* Espaciado desde el borde izquierdo de la imagen */
    font-weight: 600;
    width: 100%;

    /* Flujo de texto de derecha a izquierda */
    text-align: center;
    font-family: "Outfit", sans-serif;
  }

  .inicio-title h2 {
    position: relative;
    display: inline-flex;
    align-items: center;
    gap: 15px;
    font-size: 28px;
    font-family: "Outfit", sans-serif;
    font-weight: 500;
    padding-bottom: 15px;
    padding-top: 30px;
    color: #303031;
  }

  .inicio-title h3 {
    padding: 0px 20px 20px 20px;
    font-size: 16px;
  }

  .foto-beneficios img {
    width: 0;
    height: auto;
  }
}

/* Estilos para el título de blog */
.blog-title {
  margin-top: 80px;
  color: #303031;
}

/* Estilos para el enlace de blog */
.blog-link {
  text-decoration: none;
  color: inherit;
  font-size: 3rem;
  font-weight: bold;
  font-family: "Outfit", sans-serif;
}

/*Foto incio comienzo*/
.inicio-imagen {
  width: 100%;
  height: 480px;
  object-fit: cover;
}

.imagen-contenedor {
  position: relative;
}

.texto-encima {
  position: absolute;
  top: 50%; /* Centrado verticalmente */
  left: 0; /* Pegado al lado izquierdo */
  transform: translateY(-50%); /* Centrado verticalmente */
  color: white;
  font-size: 35px;
  padding-left: 10px; /* Opcional: separa un poco del borde izquierdo */
  font-weight: 600;
  width: 35%; /* Ajusta el ancho según necesites */
  text-align: left; /* Alineación del texto a la izquierda */
  font-family: "Outfit", sans-serif;
}

/*Foto incio */

/* Carrusel inicio */

/* Carrusel FINAL */

/* categoría inicio */

.section-categorias {
  padding: 20px 20px 70px 20px;
  max-width: 980px;
  margin: 0 auto;
}

.card-text-overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.5);
  color: white;
  padding: 10px;
  text-align: center;
}

.boton-categoria button {
  text-decoration: none;
  font-family: "Outfit", sans-serif;
  font-weight: 500;
  position: absolute;
  bottom: 20px;
  background-color: #b63232;
  padding: 4px 14px 4px 14px;
  color: #ffffff;
  border-radius: 5px;
  border: 2px solid #b63232;
}

.boton-categoria button:hover {
  background-color: #ffffff;
  border: 2px solid #b63232;
  color: #b63232;
  font-weight: bold;
  font-family: "Outfit", sans-serif;
}

.boton-categoria-mas {
  margin-top: 0px;
}

.boton-categoria-mas button {
  background-color: #ffffff;
  border: 2px solid #ffffff;
  padding: 4px 14px 4px 14px;
  font-family: "Outfit", sans-serif;
  font-weight: bolder;
  color: #b63232;
  border-radius: 8px;
}

.boton-categoria-mas button:hover {
  background-color: #ffffff;
  border: 2px solid #ffffff;
  color: #b63232;
  font-weight: bolder;
}

/* categoría FINAL */

/* Beneficios Inicio */

.section-beneficios {
  padding-bottom: 60px;
}

.Beneficios grid {
  display: flex;
  flex-direction: end;
}

.Beneficios {
  padding: 30px 0px 0px 220px;
}

.zona-texto-beneficios {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  padding-bottom: 35px;
}

.texto-beneficios {
  text-align: start;
}

.texto-beneficios h4 {
  font-family: "Outfit", sans-serif;
  font-size: 22px;
  font-weight: bold;
  color: #000000;
}

.texto-beneficios p {
  font-family: "Outfit", sans-serif;
  color: #303031;
}

.icono-beneficios {
  background-color: #b63232;
  padding: 15px 18px 15px 18px;
  border-radius: 50%;
  color: #ffffff;
}

.foto-beneficios {
  padding-left: 20px;
}

.foto-beneficios img {
  width: 450px;
  height: 400px;
  object-fit: cover;
  display: flex;
}

/* Beneficios Final */

/* Estilos del modo oscuro */

#email > span {
  color: #666681 !important;
}