/* Elementos del modo oscuro para la paginación */

.css-wjh20t-MuiPagination-ul > li {
  filter: invert(100%) !important;
}

.css-1v2lvtn-MuiPaginationItem-root > li:hover {
  filter: invert(100%) !important;
}

.dark-mode .css-wjh20t-MuiPagination-ul > li {
  filter: invert(100%) !important;
}

.dark-mode .css-g2z002-MuiSvgIcon-root-MuiPaginationItem-icon {
  color: white !important;
}

.css-1y7coo4-MuiButtonBase-root-MuiPaginationItem-root {
  color: white !important;
  border: 1px solid rgba(161, 161, 161, 0.23) !important;
}

.dark-mode .css-wjh20t-MuiPagination-ul {
  filter: invert(100%) !important;
}

.dark-mode .css-wjh20t-MuiPagination-ul.Mui-selected {
  background-color: #000000 !important;
}

.dark-mode .css-wjh20t-MuiPagination-ul.Mui-selected:hover {
  background-color: #000000 !important;
}

.dark-mode .css-1v2lvtn-MuiPaginationItem-root {
  filter: invert(100%) !important;
}

.css-1v2lvtn-MuiPaginationItem-root {
  filter: invert(100%) !important;
}

.dark-mode .css-1v2lvtn-MuiPaginationItem-root:hover {
  filter: invert(100%) !important;
}

/* Input buscar productos */

#buscar-productos-label {
  color: #000000 !important;
}

#ordenar-label {
  color: #000000 !important;
}

.dark-mode #ordenar-label {
  color: white !important;
}

.dark-mode .MuiOutlinedInput-root {
  color: white !important;
  border-color: black !important;
}

.css-muevrl > input {
  color: white !important;
}

.dark-mode #telefono-label {
  color: white !important;
}

.dark-mode .css-hfutr2-MuiSvgIcon-root-MuiSelect-icon.Mui-disabled {
  color: black !important;
  border-color: black !important;
}

.dark-mode .css-1d3z3hw-MuiOutlinedInput-notchedOutline > svg {
  color: white !important;
}

.dark-mode .formComuna > .css-1d3z3hw-MuiOutlinedInput-notchedOutline {
  color: white !important;
  border-color: white !important;
}

.dark-mode .formComuna {
  color: white !important;
  border-color: black !important;
}

.dark-mode .css-muevrl {
  color: white !important;
}

/* Menú desplegable ordenar por */

.dark-mode .css-3dzjca-MuiPaper-root-MuiPopover-paper-MuiMenu-paper {
  background-color: #000000 !important;
  color: white !important;
}

/* Botón para mostrar categorías */

.dark-mode .categoryToggleButton {
  filter: invert(100%) !important;
}