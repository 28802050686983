/* Estilos para el formulario de registro */

/* #nombre-label,
#rut-label,
#usuario-label,
#clave-label,
#telefono-label,
#correo-label,
#fechaNacimiento-label {
  color: black !important;
} */

/* #nombre-label {
  color: black !important;
  filter: invert(0%) !important;
} */

#fechaNacimiento-helper-text {
  color: #d32f2f !important;
}

.css-1fu7jd5-MuiButtonBase-root-MuiButton-root.Mui-disabled {
  background-color: rgba(0, 0, 0, 0.26) !important;
}

.css-1fu7jd5-MuiButtonBase-root-MuiButton-root {
  background-color: #d32f2f !important;
}

.css-1fu7jd5-MuiButtonBase-root-MuiButton-root:hover {
  background-color: #d32f2f !important;
}

/* Estilos en el modo oscuro */

.dark-mode .css-1jy569b-MuiFormLabel-root-MuiInputLabel-root.Mui-error {
  color: red !important;
}

.dark-mode #terminosYCondiciones > p {
    color: white !important;
}

.dark-mode svg.MuiSvgIcon-root:nth-child(2) {
  filter: invert(100%) !important;
}

.dark-mode #boxTerminosYCondiciones {
    background-color: #4A4A4A !important;
    color: white !important;
}

.dark-mode #linkTerminos {
    color: "#08027A" !important;
}