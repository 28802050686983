.switch-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2.8em; 
  height: 2.8em; 
  cursor: pointer;
  transition: transform 0.3s ease-in-out;
}

.switch-icon-img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

/* Efecto de clic */
.switch-icon:active {
  transform: scale(0.9);
}

.dark-mode .switch-icon {
  filter: invert(100%) !important;
}