.contactoForm .MuiFormHelperText-root {
  color: black !important;
}

/* Estilos del modo oscuro */

.dark-mode .locationButton {
  filter: invert(0%);
}

.dark-mode .selectedLocation {
  filter: invert(100%);
}

.dark-mode .contactoFields {
  color: white !important;
}

.dark-mode .contactoFields > #\:r3\:-label {
  color: white !important;
}

.dark-mode .contactoFields > p {
  color: red !important;
}

.dark-mode .css-hrv7el-MuiFormLabel-root-MuiInputLabel-root {
  color: white !important;
}

.dark-mode div.Mui-error > fieldset:nth-child(2) > legend:nth-child(1) > span:nth-child(1) {
  color: black !important;
}

.dark-mode .contactoFields > span {
  color: white !important;
}

.dark-mode .css-109p6wb-MuiInputBase-root-MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline {
  border-color: white !important;
}

.dark-mode .css-1qqg1e4-MuiFormLabel-root-MuiInputLabel-root {
  color: white !important;
}

.dark-mode .css-1qqg1e4-MuiFormLabel-root-MuiInputLabel-root.Mui-error {
  color: white !important;
}

.dark-mode .css-yjsfm1 > span {
  filter: invert(100%) !important;
}

.dark-mode .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input {
  color: white !important;
}

/* Tu consulta ha sido enviada exitosamente */

.dark-mode .css-18adfq7-MuiTypography-root {
  color: white !important;
}