/* Estilos del modo oscuro en el ConfirmarProductos */

.dark-mode
  .seccionEntrega
  > .css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root.Mui-checked
  > svg {
  filter: invert(100%) !important;
}

.dark-mode
  .seccionEntrega
  > .css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root.Mui-checked
  > .css-14u90gq-MuiFormControl-root {
  background-color: #00b2b2;
}

.dark-mode
  .seccionEntrega
  > .css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root.Mui-checked
  > .formRegion {
  background-color: #00b2b2 !important;
}

.dark-mode
  .seccionEntrega
  > .css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root.Mui-checked
  > input {
  background-color: #00b2b2 !important;
}

.dark-mode
  .seccionEntrega
  > .css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root.Mui-checked
  > .css-yf8vq0-MuiSelect-nativeInput {
  filter: invert(50%) !important;
}

.dark-mode
  .seccionEntrega
  > .css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root.Mui-checked
  > .MuiOutlinedInput-root {
  filter: invert(100%) !important;
}

.dark-mode .retiroEnBodega {
  color: black !important;
}

.dark-mode #region-label {
  color: black !important;
}

.dark-mode
  .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.Mui-disabled {
  border-color: black !important;
  filter: invert(100%) !important;
}

.dark-mode div.MuiFormControl-root:nth-child(2) > div:nth-child(2) > input:nth-child(2) {
  border-color: black !important;
  filter: invert(0%) !important;
}

.dark-mode #comuna-label {
  color: black !important;
}

.dark-mode .formComuna {
  color: black !important;
  border-color: black !important;
}

.dark-mode .formRegion > svg {
  color: black !important;
  border-color: black !important;
}

.dark-mode .formComuna > svg {
  color: black !important;
  border-color: black !important;
}

.dark-mode .region-select {
  filter: invert(0%) !important;
}

.dark-mode .region-select > svg {
  filter: invert(100%) !important;
}

.dark-mode .checkboxRetiro {
  margin-bottom: 2em !important;
}

/* Estilos de los modos de envio */

.dark-mode .css-15o04vv-MuiPaper-root {
  background-color: #fb4b4b !important;
}

.dark-mode .css-51qhci-MuiPaper-root {
  background-color: #fb4b4b !important;
}

/* Checkbox de los envios */

.dark-mode
  div.MuiPaper-root:nth-child(1)
  > span:nth-child(1)
  > input:nth-child(1) {
  color: black !important;
  filter: invert(100%) !important;
}

/* Checkbox de los envios */

.dark-mode .css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root.Mui-checked {
  color: black !important;
}

.dark-mode .seccionEntrega > svg {
  color: black !important;
}

/* Estilos de los botones inferiores */

.dark-mode .MuiButton-text:nth-child(3) {
  background-color: #FF4D4D;
  color: white !important;
}

.dark-mode .css-1f2e42z-MuiButtonBase-root-MuiButton-root.Mui-disabled {
  color: black !important;
  background-color: transparent !important;
  opacity: 0.3 !important;
}

.dark-mode .css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root.Mui-checked > svg {
  filter: invert(100%) !important;
}

.Mui-checked > svg:nth-child(2) {
  filter: invert(100%) !important;
}

.dark-mode .datosEntrega {
  color: white !important;
}

.dark-mode .css-18eor76-MuiFormLabel-root-MuiInputLabel-root {
  color: white !important;
}

.dark-mode .datosEntrega > label {
  color: white !important;
}

/* Mensajes de error en el formulario */

.dark-mode .css-1wc848c-MuiFormHelperText-root.Mui-error {
  color: red !important;
}

.dark-mode svg.MuiSvgIcon-root:nth-child(2) {
  filter: invert(0%) !important;
}

/* Botón atrás */

.dark-mode .css-fsxd1h-MuiButtonBase-root-MuiButton-root {
  background-color: #FF4D4D !important;
  color: white !important;
}

.dark-mode .region-select > div:nth-child(1) {
  filter: invert(100%) !important;
}

.dark-mode div.MuiFormControl-root:nth-child(2) > div:nth-child(2) > div:nth-child(1) {
  filter: invert(100%) !important;
  border-color: white !important;
}

.dark-mode .region-select {
  border-color: white !important;
}