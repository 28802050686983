/* Estilos de los íconos circulares de los pasos de compra */

.css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root.Mui-active {
    color: #B63232 !important;
}

.css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root.Mui-completed {
    color: #B63232 !important;
}

/* Color del botón seguir comprando */

.css-12v850q-MuiButtonBase-root-MuiButton-root{
    background-color: #B63232 !important;
    border-color: #B63232 !important;
}

.css-12v850q-MuiButtonBase-root-MuiButton-root:hover {
    background-color: #B63232 !important;
    border-color: #B63232 !important;
}