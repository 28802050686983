/* Estilos del modo oscuro en el Blog */

.dark-mode .tarjeta-blog {
  border: 1px solid white !important; /* Borde blanco delgado */
  box-shadow: none !important; /* Mantiene la sombra desactivada */
  border-radius: 8px;
}

.dark-mode .tarjetaBlog {
  background-color: red;
}

body.dark-mode .container .section {
  filter: invert(0%) !important;
}

/* Cambio de tipografías en Blog */

.contenido-tarjeta > p {
  font-family: "Outfit", sans-serif !important;
}