/* Estilos del SearchBar en modo normal */

#buscar-productos {
  font-family: "Outfit", sans-serif !important;
}

#ordenar-label {
  font-family: Outfit, sans-serif !important;
}

.MuiTextField-root {
  color: white !important;
  border-color: white !important;
}

.css-1jy569b-MuiFormLabel-root-MuiInputLabel-root {
  color: white !important;
  border-color: white !important;
}

/* Estilos del SearchBar en modo oscuro */

body.dark-mode .ordenar-label {
  filter: invert(80%) !important;
}

body.dark-mode .buscar-productos {
  filter: invert(100%) !important;
  color: black !important;
}

body.dark-mode .buscar-productos ::placeholder {
  color: #000000;
}

.dark-mode #ordenar-label {
  font-family: Outfit, sans-serif !important;
}

.dark-mode #buscar-productos {
  color: white !important;
  border-color: white !important;
}

.dark-mode #buscar-productos-label {
  color: white !important;
  border-color: white !important;
}

/* Labels */

.dark-mode .css-md26zr-MuiInputBase-root-MuiOutlinedInput-root {
  color: white !important;
}

/* Bordes del label buscar productos */

.dark-mode .css-1d3z3hw-MuiOutlinedInput-notchedOutline {
  color: black !important;
  border-color: white !important;
}

.dark-mode .css-1nreaws-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root {
  color: white !important;
}

.dark-mode .css-hfutr2-MuiSvgIcon-root-MuiSelect-icon {
  filter: invert(100%) !important;
}

.dark-mode
  .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
  -webkit-text-fill-color: #fff !important;
  background-color: initial !important;
  border-color: #fff !important;
  color: #fff !important;
  font-family: Outfit, sans-serif !important;
}

/* Estilos de las product cards */

.dark-mode .css-1yxjo4t-MuiPaper-root-MuiCard-root {
  background-color: #000000 !important;
}

